import { SHARED_MODEL } from ":mods";

export function contactMock(success: boolean): Promise<SHARED_MODEL.ApiResponseSchema<{ data: { name: string }[] }[]>> {
  if (success)
    return Promise.resolve({
      message: "success",
      error: null,
      data: [{ data: [{ name: "Jeddah" }, { name: "Monaco" }, { name: "Austrie" }] }],
    });
  return Promise.reject({
    message: "error",
    error: null,
    data: null,
  });
}
